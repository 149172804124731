import React, { useState } from "react";

import addToMailchimp from "gatsby-plugin-mailchimp";
import { navigate } from 'gatsby'
import styles from "./EmailSignup.module.scss";

const EmailSignup = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(undefined);
  const [pending, setPending] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pending) {
      return;
    }

    setPending(true);

    addToMailchimp(email)
      .then((data) => {
        if (data.result === "success") {
          setEmail("");
          setPending(false);
          setError(undefined);
          navigate('/subscriber');
        } else {
          setPending(false);
          setError("There was an issue signing you up. Please try again!");
        }
      })
      .catch(() => {
        setPending(false);
        setError("There was an issue signing you up. Please try again!");
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <div className={styles["email-signup__container"]}>
      <form onSubmit={handleSubmit} className={styles.EmailListForm}>
        <p className={styles["email-signup__headline"]}>
          Get notified when I post a new article
        </p>
        <p>
          Your email address will <em>never</em> be shared or spammed. You'll
          receive a notification when I post a new article and that's it.
        </p>
        <div className={styles["email-signup__wrapper"]}>
          <input
            className={styles["email-signup__input"]}
            placeholder="Email address"
            name="email"
            type="text"
            onChange={handleEmailChange}
          />
          <button className={styles["email-signup__button"]} type="submit" disabled={pending}>
            Subscribe
          </button>
        </div>
        {error && <p className={styles["email-signup__error"]}>{error}</p>}
      </form>
    </div>
  );
};

export default EmailSignup;
