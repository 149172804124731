import React from 'react';
import styles from './Content.module.scss';
// @flow strict
import { withPrefix } from "gatsby";

type Props = {
  body: string,
  title: string,
  showSocialImageInPost: boolean,
  socialImage: string
};

const Content = ({ body, title, showSocialImageInPost, socialImage }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    { showSocialImageInPost && socialImage && (
       <img
       src={withPrefix(socialImage)}
       className={styles['content__image']}
       width="75"
       height="75"
       alt={title}
     />
    )}
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
