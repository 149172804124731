// @flow strict
import React from "react";
import dates from "../../../utils/dates";
import styles from "./Meta.module.scss";

type Props = {
  date: string,
};

const Meta = ({ date }: Props) => (
  <div className={styles["meta"]}>
    <p className={styles["meta__date"]}>
      Published {dates.formatFullDate(date)}
    </p>
  </div>
);

export default Meta;
